import { auth, db, FirebaseFieldValue } from "../config/main_config";

const signOut = async function () {
  const usersRef = db.collection("users").doc(auth.currentUser.uid);
  await usersRef.get().then(async (doc) => {
    if (doc.exists) {
      if (doc.data().roles.msp == true && doc.data().mspImpersonation == true) {
        await usersRef.update({
          organisations: FirebaseFieldValue.arrayRemove(doc.data().organisations[1]),
          mspImpersonation: false,
        }).then(() => {
          window.location = "msp-view.html";
        });
      } else {
        if (doc.data().organisations.length > 1) {
          await usersRef.update({
            organisations: FirebaseFieldValue.arrayRemove(doc.data().organisations[1]),
            mspImpersonation: false,
          }).then(() => {
            window.location = "msp-view.html";
          });
        }
        auth.signOut().then(() => {
          window.location = "login.html";
        }).catch(error => {// An error happened.
        });
      }
    }
  }).catch((e) => {
    console.error(e);
    auth.signOut().then(() => {
      window.location = "login.html";
    }).catch(error => {// An error happened.
    });
  })
}

const forceSignOut = async function () { // This function is used to force the user to sign out and redirect to the login page. Called from public/src/js/external-js/variableModules/standardOnLoadFunction.module.js:31
  const usersRef = db.collection("users").doc(auth.currentUser.uid);
  const usersDoc = await usersRef.get();
  if (usersDoc.data().organisations.length > 1) {
    await usersRef.update({
      organisations: FirebaseFieldValue.arrayRemove(usersDoc.data().organisations.find(org => org !== window.companyID)),
      mspImpersonation: false,
    }).then(() => {
      window.location = "msp-view.html";
      return;
    });
  }
  auth.signOut().then(() => {
    window.location = "login.html";
    return;
  }).catch(error => {
    console.log(error);
  });
}

window.signOut = signOut;
window.forceSignOut = forceSignOut;

export { signOut, forceSignOut };